import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AdventHealth1LandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Weaver"
    institution="Advent Health"
    referralCode="ADVENT1"
    physicianURL="https://www.adventhealthmedicalgroup.com/central-florida/physician/npi/1598863219"
  />
)

export default AdventHealth1LandingPage
